<template>
  <RouterView />
  <div class="departments pos-r z1 pt-20 pb-30">
    <div class="flex ai-c jc-sb ggap-20">
      <h2 class="title">Все отделы</h2>
      <div class="flex ai-c ggap-20">
        <FormInput label="" type="search" placeholder="Поиск..." v-model="searchText" />
        <RouterLink to="departments/add" append class="btn primary">
          <BaseIcon class="ic-16 white" icon="plus-circle" />
          Добавить отдел
        </RouterLink>
      </div>
    </div>

    <div class="box mt-20">
      <table class="base-table w-100">
        <thead>
          <th>ID</th>
          <th>Название</th>
          <th>Участники</th>
        </thead>

        <tbody>
          <Row v-for="row of rows" :key="row.id" :row="row" @deleteAction="deleteAction" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import departments from '@/api/modules/departments'
import { useStore } from 'vuex'
import { defineEmits, computed, ref } from 'vue'
import { BaseIcon, FormInput } from '@/components'
import Row from './components/Row.vue'
import { $busEmit } from '@/plugins'

// Emits
const emits = defineEmits(['loadAction'])

// Data
const store = useStore()
const searchText = ref('')

// Computed
const rows = computed(() => {
  return searchText.value
    ? store.getters['helpers/departments']?.filter((item) => {
        const currentItem = item.name.toLowerCase()
        return currentItem.includes(searchText.value.toLowerCase()) && item
      })
    : store.getters['helpers/departments']
})

// Created
if (!rows.value?.length) {
  emits('loadAction', true)
  store.dispatch('helpers/GET_DEPARTMENTS').finally(() => emits('loadAction', false))
}

// Methods
function deleteAction(id) {
  emits('loadAction', true)
  departments
    .delete(id)
    .then(() => {
      store.dispatch('helpers/GET_DEPARTMENTS').finally(() => {
        emits('loadAction', false)
        $busEmit('setToast', {
          type: 'green',
          icon: 'check-circle',
          message: 'Отдел успешно удален'
        })
      })
    })
    .finally(() => {
      emits('loadAction', false)
    })
}
</script>

<style></style>
