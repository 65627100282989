<template>
  <tr class="statuses__row pos-r" :class="{ active: isDeleteAction }">
    <td class="statuses__row-id">{{ row.id }}</td>
    <td>
      <b>{{ row.name }}</b>
    </td>
    <td class="statuses__row-last">
      <div v-if="row.users && row.users.length" class="flex ai-c statuses__row-users">
        <div v-for="user of users" :key="user.id" class="cover ava-30">
          <img :src="user.avatar || require('@/assets/img/ava.svg')" />
        </div>
        <small v-if="row.users.length > 5" class="t-grey-dark fs-12 cut ml-10">Всего: {{ row.users.length }} </small>
      </div>
      <div v-else class="t-grey-dark">&mdash;</div>
    </td>
    <div class="statuses__actions flex ai-c ggap-5 pr-5">
      <div class="flex ai-c ggap-10" v-if="isDeleteAction">
        <h4 class="title">Вы действительно хотите удалить?</h4>
        <div class="flex ai-c ggap-5">
          <button class="btn sm red pl-10 pr-10" @click.prevent="emits('deleteAction', row.id)">Удалить</button>
          <button class="btn sm cancel pl-10 pr-10" @click.prevent="isDeleteAction = false">Отмена</button>
        </div>
      </div>
      <template v-else>
        <RouterLink
          :to="'departments/edit/' + row.id"
          append
          class="btn sm outline pl-10 pr-10"
          v-tippy="'Редактировать'"
        >
          <BaseIcon class="ic-16 primary" icon="edit" />
        </RouterLink>
        <button
          :disabled="row.is_static"
          class="btn sm outline pl-10 pr-10"
          v-tippy="'Удалить'"
          @click.prevent="deleteToggler"
        >
          <BaseIcon class="ic-16 red" icon="delete" />
          <!-- Удалить -->
        </button>
      </template>
    </div>
  </tr>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref, toRefs } from 'vue'
import { BaseIcon } from '@/components'

// Emits
const emits = defineEmits(['deleteAction'])

// Props
const props = defineProps(['row'])

// Data
const { row } = toRefs(props)
const isDeleteAction = ref(false)

// Computed
const users = computed(() => {
  return row.value.users.slice(0, 5)
})

// Methods
function deleteToggler() {
  if (!row.value.is_static) isDeleteAction.value = !isDeleteAction.value
}
</script>

<style lang="scss">
.statuses {
  &__actions {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    padding-left: 200px;
    background: linear-gradient(to right, transparent, var(--grey) 70%);
  }
  &__row:hover &__actions,
  &__row.active &__actions {
    opacity: 1;
  }
  &__row.active &__actions {
    background: linear-gradient(to right, transparent, var(--grey) 35%);
  }
  &__row-id {
    width: 50px;
  }
  &__row-last {
    width: 240px;
  }
  &__row-users {
    .cover {
      margin-left: -5px;
      box-shadow: 0 0 0 2px #fff;

      &:first-child {
        margin: 0;
      }
    }
  }
}

.dark {
  .statuses {
    &__row-users {
      .cover {
        box-shadow: 0 0 0 2px var(--box);
      }
    }
  }
}
</style>
